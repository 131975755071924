/*! Defaults design for the buttons */
@use "./var" as *;

/* Default sizing for buttons */
.button,
.button-dark {
	padding: 0.8em 1.4em;
	margin: 1.2rem 1.2rem 1.2rem 0;
}

/* Base Button Properties */
.button,
.inverted-button,
.button-dark {
	display: inline-block;
	border-radius: 26px;
	font-size: 16px;
	font-weight: bold;
	text-align: center;
	text-decoration: none !important; /* Overwrite default a text decorations */
	box-sizing: border-box; /* Include border into the size-properties */
}

/* Default Button */
.button {
	border: none;
	color: white;
	background-color: $scheme-primary !important; /* Overwrite default background color */

	&:active,
	&:visited {
		color: white !important;
	}

	&:hover {
		background-color: $button-dark-primary !important;
		box-shadow: 0 0 0.5em $scheme-gray !important;
	}
}

.docs-button {
	padding: 10px 14px;
	margin: 1.2rem 0.6rem 1.2rem 0;
}

/* Darker Button */
.button-dark {
	border: none !important;
	color: white !important;
	background-color: $button-dark-primary !important;

	&:active,
	&:visited {
		background-color: $button-dark-primary !important;
		color: white;
	}

	&:hover {
		background-color: $button-dark-primary !important;
		box-shadow: 0 0 0.5em $scheme-gray !important;
		color: white;
	}
}

/* Inverted Transparent-Background Button */
.inverted-button {
	border: 2px solid $scheme-primary;
	color: $scheme-primary;
	background-color: transparent;
	padding: calc(0.8em - 2px) calc(1.4em - 2px);
	margin: 1.2rem 1.2rem 1.2rem 0;

	&:active,
	&:visited {
		color: $scheme-primary;
	}

	&:hover {
		background-color: $button-dark-primary !important;
		box-shadow: 0 0 0.5em $scheme-gray;
		color: white !important;
		border-color: $button-dark-primary;
	}
}

/* Underline buttons */
.underline-button {
	position: relative;
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	box-sizing: border-box;

	& h1,
	& h2,
	& h3,
	& h4,
	& h5,
	& h6,
	& a,
	& p {
		margin: 0.25rem 0;
	}

	&::before {
		content: "";
		position: absolute;
		display: block;
		width: 100%;
		height: 2px;
		bottom: 0;
		left: 0;
		background-color: white;
		transform: scaleX(0);
		transform-origin: top left;
		transition: transform 0.4s ease;
	}

	&:hover::before {
		transform: scaleX(1);
	}

	&:hover {
		color: white !important;
	}
}

/* Navigation Menu Button */
@media (max-width: 1200px) {
	.top-nav-button {
		margin: 0.25em 0.5em !important;
	}
}

@media (min-width: 1200px) {
	.top-nav-button {
		margin: 0.25em 1em !important;
	}
}
